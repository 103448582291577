import { reviewStore } from "../stores/stores";


export async function createResourceChip(name: string, id: number, employeesWithTenderReceivedArray: number[], icon?: any, overlappingType?: any) {
    const employeeRequestElement = await getEmployeeRequestElement(id);
    disableTenderForEmployee(employeeRequestElement);

    const div = createChipElement(name, id, employeesWithTenderReceivedArray, icon, overlappingType);
    appendChipToParent(div);

    div.addEventListener('click', () => handleChipClick(div, id, employeeRequestElement));
}

async function getEmployeeRequestElement(id: number) {
    return document.querySelector(`.employee-drag[data-ressourceid="${id}"]`);
}

function disableTenderForEmployee(employeeRequestElement: Element) {
    if(employeeRequestElement) {
        employeeRequestElement.classList.add('disabled-tender');
    }
}

function createMailTenderIcon() {
    let mailTenderIcon = document.createElement('div');
    mailTenderIcon.classList.add("mail-tender-icon");
    mailTenderIcon.innerHTML = `<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3038_369)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24 9.16406C24 8.93437 23.7281 8.79844 23.5453 8.94375C22.5463 9.7198 21.2417 10.6996 16.9989 13.7848L17.2097 13.7822C18.5462 13.7822 19.857 13.1342 20.9012 12.2777L22.375 11.1551C23.2413 10.8698 23.7313 11.0269 24 11.2358V9.16406ZM13.3389 21C13.3366 20.9299 13.3355 20.8595 13.3355 20.7888C13.3355 20.5163 13.1667 20.2535 12.625 20.2535C11.5417 20.2535 11 19.1831 11 18.3804V16.3435C9.93995 16.0124 8.93562 15.2375 8.17244 14.6486C7.99364 14.5106 7.82807 14.3829 7.67813 14.2734C2.89688 10.8 1.50469 9.76406 0.454687 8.94844C0.267187 8.80312 0 8.93438 0 9.16875V18.75C0 19.9922 1.00781 21 2.25 21H13.3389ZM11.4522 14.9119C10.6428 14.6632 9.70806 13.9408 9.02622 13.4139C8.85198 13.2792 8.69427 13.1573 8.55938 13.0594C2.79418 8.87559 1.96567 8.22721 0.733286 7.26277C0.636 7.18663 0.536197 7.10853 0.43125 7.02656C0.159375 6.81094 0 6.4875 0 6.14062V5.25C0 4.00781 1.00781 3 2.25 3H21.75C22.9922 3 24 4.00781 24 5.25V6.14062C24 6.4875 23.8406 6.81562 23.5687 7.02656C23.4883 7.08964 23.4109 7.15042 23.3354 7.20976C22.0632 8.20867 21.3122 8.79842 15.4406 13.0594C15.3057 13.1573 15.148 13.2792 14.9738 13.4139C14.811 13.5397 14.6337 13.6767 14.4472 13.8157L13.1667 13.8312C12.6243 13.8312 11.9095 14.3249 11.4522 14.9119Z" fill="#202124"/>
    <path d="M22.9278 16.2564V15.972V12.6898C22.9278 12.6901 22.9278 12.6902 22.9277 12.6902C22.9276 12.6903 22.9263 12.6731 22.9177 12.6445C22.908 12.6122 22.8938 12.5814 22.8771 12.5572C22.8613 12.5342 22.8469 12.5223 22.8354 12.5158C22.8262 12.5106 22.8042 12.5 22.7556 12.5C22.7203 12.5 22.684 12.5119 22.6528 12.5375L22.6525 12.5378L20.8663 13.9994L20.8661 13.9995C19.8805 14.8052 18.6426 15.25 17.3778 15.25H13.3444C12.8885 15.25 12.5 15.631 12.5 16.125V18.1875C12.5 18.6815 12.8885 19.0625 13.3444 19.0625H14.0524H14.6214L14.5482 19.6268C14.5215 19.833 14.5066 20.0404 14.5066 20.25C14.5066 21.0277 14.6835 21.762 14.9952 22.4191L22.9278 16.2564ZM22.9278 16.2564L23.1722 16.4018M22.9278 16.2564L23.1722 16.4018M23.1722 16.4018C23.4274 16.5536 23.6 16.8324 23.6 17.1562C23.6 17.48 23.4275 17.7589 23.1719 17.911L22.9276 18.0564V18.3407V21.625C22.9276 21.6236 22.9276 21.6236 22.9274 21.625C22.9269 21.6294 22.925 21.6466 22.9172 21.6723C22.9077 21.7041 22.8937 21.7339 22.8777 21.757C22.8624 21.7788 22.8486 21.7902 22.8371 21.7966C22.8278 21.8018 22.805 21.8125 22.7553 21.8125V22.3125L22.7558 21.8125C22.7196 21.8125 22.6831 21.7999 22.6523 21.7747L22.6523 21.7747L20.866 20.3131L20.8659 20.313C19.8805 19.5073 18.6426 19.0625 17.3778 19.0625H16.7879H16.404M23.1722 16.4018L16.404 19.0625M16.404 19.0625L16.3048 19.4334M16.404 19.0625L16.3048 19.4334M16.3048 19.4334C16.2362 19.6904 16.1953 19.9633 16.1953 20.25C16.1953 20.9856 16.4367 21.6664 16.8437 22.2125C16.8839 22.2665 16.8892 22.3324 16.8559 22.3984C16.8219 22.4655 16.7644 22.5 16.7005 22.5H15.1401C15.0668 22.5 15.0145 22.4596 14.9953 22.4191L16.3048 19.4334ZM21.7667 20.5815L22.5833 21.2497V20.1946V14.1179V13.0628L21.7667 13.731L21.0724 14.299C20.0265 15.1547 18.7133 15.625 17.3778 15.625H16.8778V16.125V18.1875V18.6875H17.3778C18.7133 18.6875 20.0265 19.1578 21.0724 20.0135L21.7667 20.5815Z" fill="#202124" stroke="#202124"/>
    </g>
    <defs>
    <clipPath id="clip0_3038_369">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>    `;
    return mailTenderIcon;
}


function createDiv() {
    let warningIcon = document.createElement('div');
    warningIcon.classList.add('warning-icon');
    return warningIcon;
}

function createChipElement(name: string, id: number, employeesWithTenderReceivedArray: number[], icon?: any, overlappingType?: string) {
    const div = document.createElement('div');
    const p = createParagraphElement(name);
    const svg = createSvgElement();
    div.appendChild(p);
    div.appendChild(svg);
    div.setAttribute('data-ressourceid', id.toString());
    div.setAttribute('data-ressourcename', name);
    div.setAttribute('data-overlap-icon', icon);
    div.setAttribute('data-overlap-type', overlappingType);

    div.classList.add('tender-recipient-chip');

    if (employeesWithTenderReceivedArray.includes(id)) {
        let mailTenderIcon = createMailTenderIcon();
        div.appendChild(mailTenderIcon);
        div.classList.add('tender-already-requested');
    } else if(overlappingType === 'zeit') {
        let warningIcon = createDiv();
        warningIcon.innerHTML = icon
        div.appendChild(warningIcon);
        div.classList.add('tender-overlap')
    } else if (overlappingType === 'tag') {
        let warningIcon = createDiv();
        warningIcon.innerHTML = icon
        div.appendChild(warningIcon);
        div.classList.add('rest-periods');
    }

    return div;
}

function createParagraphElement(name: string) {
    const p = document.createElement('p');
    p.classList.add("tender-resource-name-chip");
    p.textContent = name;
    return p;
}

function createSvgElement() {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('height', '16');
    svg.setAttribute('width', '12');
    svg.setAttribute('viewBox', '0 0 384 512');

    const xIconPath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    xIconPath.setAttribute('d', 'M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z');
    xIconPath.setAttribute('stroke', 'black');
    xIconPath.setAttribute('stroke-width', '10');

    svg.appendChild(xIconPath);
    svg.classList.add("xIcon");

    return svg;
}

function addTooltipIfOverflow(element: HTMLElement) {
    if (element.offsetWidth < element.scrollWidth) {
        element.title = element.textContent;
    }
}

function appendChipToParent(div: HTMLDivElement) {
    const droppedChildParent = document.querySelector('.dropped-children-tender-parent');
    droppedChildParent.appendChild(div);
    const p = div.firstChild as HTMLElement;
    addTooltipIfOverflow(p);
}

function handleChipClick(div: HTMLDivElement, id: number, employeeRequestElement: Element) {
    div.remove();
    reviewStore.update(storeValue => {
        return {
            ...storeValue,
            recipients: storeValue.recipients.filter(recipient => recipient.id !== id)
        };
    });
    if(employeeRequestElement) {
        employeeRequestElement.classList.remove('disabled-tender');
    };
}
